// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fr-contact-js": () => import("./../src/pages/fr/contact.js" /* webpackChunkName: "component---src-pages-fr-contact-js" */),
  "component---src-pages-fr-js": () => import("./../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-fr-our-mission-js": () => import("./../src/pages/fr/our-mission.js" /* webpackChunkName: "component---src-pages-fr-our-mission-js" */),
  "component---src-pages-fr-our-team-js": () => import("./../src/pages/fr/our-team.js" /* webpackChunkName: "component---src-pages-fr-our-team-js" */),
  "component---src-pages-fr-projects-js": () => import("./../src/pages/fr/projects.js" /* webpackChunkName: "component---src-pages-fr-projects-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-mission-js": () => import("./../src/pages/our-mission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-our-team-js": () => import("./../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

